const featuredImages = [
    
    "u8lm0jYT876l1mHZj5zD",
    "Yh2P0h7oLb1YijjnoBAM",
    "7orU4EyebhDVa1QVlGxa",
    "JzniQ3JAdefPCtzMn8zM",
    "uuWbgAdb3umIb1EN3jag",
    "pRSY67JWCwXfjaN5qeXo",
    "9aTJCpBGBgdFY026CPKg",
    "wiIfKcNPRrPl8zWXsMbn",
    "x0mdh6yL2xabUMtQH3U3",
    "XSImNiYeWQP6HDMRzIOI"
]

export default featuredImages
