import React, { useState, useEffect  } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { apiRequest } from "../util/util.js";
import { getPaypalId } from "../util/prices.js";


function PaypalCheckoutButton(props) {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "enable-funding": "venmo",
    "disable-funding": "paylater,card",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const { productId, setMessage, setErrorMessage } = props;
  console.log(productId)

  const handleCreateOrder = async () => {
    try {
      const response = await fetch("/api/paypal-create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: getPaypalId(productId),
              quantity: 1,
            },
          ],
        }),
      });
      const orderData = await response.json();
      if (orderData.id) {
        console.log(orderData.id)
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`Could not initiate PayPal Checkout...${error}`);
    }
  };

  const handleApprove = async (data, actions) => {
    console.log('approve')
    console.log(data)
    try {
      const response = await fetch(
        `/api/paypal-capture-order/${data.orderID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const orderData = await response.json();
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        throw new Error(
          `${errorDetail.description} (${orderData.debug_id})`,
        );
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');
        const transaction = orderData.purchase_units[0].payments.captures[0];
        setMessage('Credit purchase successful. Wait a minute for your credit to update.')
        // setMessage(
        //   `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
        // );
        // console.log(
        //   "Capture result",
        //   orderData,
        //   JSON.stringify(orderData, null, 2),
        // );
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Sorry, your transaction could not be processed...${error}`,
      );
    }
  };

  return (
    <div className="PaypalButton">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={handleCreateOrder}
          onApprove={handleApprove}
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PaypalCheckoutButton;