import React from "react";
import Meta from "../components/Meta";
import ItemSection from "../components/ItemSection";
import ContentCardsSection from "../components/ContentCardsSection";

function ItemPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <ItemSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
        {/* <ContentCardsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Featured Content"
        subtitle=""
      /> */}
    </>
  );
}

export default ItemPage;
