import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useItem, updateItem, createItem } from "../util/db";
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    '&:first-child':{
      padding: 0
    }
  },
  media: {
    height: 0,
    paddingTop: '133%' //4 : 3
  },
  cardContent: {
    padding: 30,
    fontSize: 14,
    '& h6': {
      color: theme.palette.grey[400],
    },
    '& item':{
      margin: 20
    }
  },
  iconButton: {
    margin: 5
  }

}));

function ItemModal(props) {
  const classes = useStyles();

  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const item = props.item

  const onSubmit = (data) => {
    setPending(true);

    const query = props.id
      ? updateItem(props.id, data)
      : createItem({ owner: auth.user.uid, ...data });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <Dialog open={true} onClose={props.onDone} maxWidth="md" fullWidth>
      <DialogContent className={classes.root}>
      <Grid container={true}>
          <Grid item={true} xs={12} md={6}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image={item?.url}
              title="Paella dish"
            />
            {/* <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions> */}
          </Card>
          </Grid>
          <Grid item={true} xs={12} md={6}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography variant="h6">
                    <strong>Prompt</strong>
                  </Typography>
                  <Typography paragraph={true}>
                    {item.userPrompt}
                  </Typography>
                  {item.userNegativePrompt !== "" &&
                  <Box>
                    <Typography variant="h6">
                      <strong>Negative Prompt</strong>
                    </Typography>
                    <Typography paragraph={true}>
                      {item.userNegativePrompt}
                    </Typography>
                  </Box>
                  }
                  
                  <Box mt={3}>
                    <Grid container={true}>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Style</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.style.toUpperCase()}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Pose</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.pose}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Seed</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.seed}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Created At</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {new Date(item.createdAt.seconds * 1000).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Created By</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.ownerName || "Anonymous"}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12} style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Button variant="outlined" edge="start" className={classes.iconButton} endIcon={<CopyIcon />} onClick={() => props.onClone(item)}>
                            Clone
                        </Button>
                        {auth.user && auth.user.uid == item.owner &&
                        <Button variant="outlined" edge="start" className={classes.iconButton} endIcon={<DeleteIcon />} onClick={() => props.onDelete(item.id)}>
                            Delete
                        </Button>
                        }
                        <Button variant="outlined"  className={classes.iconButton} endIcon={<FavoriteIcon color={props.likes[item.id]? 'primary':'white'} />} onClick={() => props.onLike(item.id)}>
                          {(Boolean(item.likesCount) + (Boolean(props.likes[item.id]) - Boolean(props.initLikes[item.id]))) }
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ItemModal;
