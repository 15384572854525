import React, { useState } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { apiRequest } from '../util/util';


const useStyles = makeStyles((theme) => ({
  fullWidthBox: {
    width: '100%', // Full width
    display: 'flex', // Use flexbox
    justifyContent: 'center', // Horizontally center content
    alignItems: 'center', // Vertically center content (if needed)
    gap: theme.spacing(1),
    marginBottom: 20,
    marginTop: 20
    // Add any additional styling such as padding, margins, borders, etc.
  },
}));

function DeleteItemForm() {
  const [userId, setUserId] = useState('');
  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!userId) {
      window.alert('User ID is empty')
      return
    }

    // Confirm user's intention
    if (window.confirm('Are you sure you want to delete items for this user?')){
      try {
        await apiRequest('delete-items', 'POST', {ownerId: userId})
        window.alert(`All images owned by user ${userId} have been deleted.`);
        // Handle success (e.g., show a success message)
      } catch (error) {
        console.error('Error deleting items:', error);
        window.alert(`Error deleting items: ${error.message}`);
        // Handle error (e.g., show an error message)
      }
    }
  
    
  };

  return (
    <Box
      component="form"
      className={classes.fullWidthBox}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        label="User ID"
        variant="outlined"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
      <Button variant="contained" size='large' color='primary' type="submit">
        Delete All Items by User
      </Button>
    </Box>
  );
}

export default DeleteItemForm;