import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import Button from "@material-ui/core/Button";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { useItem, useItemsMutation, likeItem, deleteItem } from "./../util/db";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    margin: 'auto',
    backgroundColor: 'primary.main',
  },
  media: {
    height: 0,
    paddingTop: '133%' //4 : 3
  },
  cardContent: {
    '& h6': {
      color: theme.palette.grey[400],
      fontSize: 16
    },
    '& item':{
      margin: 20
    }
  },
  iconButton: {
    margin: 5
  }
}));

function ItemSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter()

  const { data: item, status: itemStatus } = useItem(router.query.id);

  const likeMutation = useItemsMutation(likeItem)
  const [likes, setLikes] = useState({})
  const [initLikes, setInitLikes] = useState({})
  
  const handleLike = (itemId) => {
    if (!auth.user) {
      alert("Sign in to like this image")
      return
    }
    setLikes(prevState => {
      return {...prevState, [itemId]: !prevState[itemId]}
    })
    likeMutation.mutate(itemId)
  }
  const deleteMutation = useItemsMutation(deleteItem)

  const handleDelete = (itemId) => {
    deleteMutation.mutate(itemId)
  }

  const handleClone = (item) => {
    router.push(`/create?style=${item.style}&pose=${item.pose}&prompt=${item.userPrompt}&negativePrompt=${item.userNegativePrompt}`)
  }

  const handleClick = (item) => {
    router.push('/images/' + item.id)
    // setViewingItemData(item)
    // setViewingItem(true)
  }

  useEffect(() => {
    if (auth.user && (Array.isArray(auth.user.likes)) && (Object.keys(initLikes).length === 0)){
      let newLikes = {}
      for(const itemId of auth.user.likes){
        newLikes[itemId] = true
      }
      setInitLikes(newLikes)
      setLikes(newLikes)
      
    }
  }, [auth]);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        {!item && itemStatus !== "success" ? <Typography>Loading Image ...</Typography> :
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={6}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image={item?.url}
              title="Paella dish"
            />
          </Card>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography variant="h6">
                    <strong>Prompt</strong>
                  </Typography>
                  <Typography paragraph={true}>
                    {item.userPrompt}
                  </Typography>
                  {item.userNegativePrompt !== "" &&
                  <Box>
                    <Typography variant="h6">
                      <strong>Negative Prompt</strong>
                    </Typography>
                    <Typography paragraph={true}>
                      {item.userNegativePrompt}
                    </Typography>
                  </Box>
                  }
                  <Box mt={3}>
                    <Grid container={true}>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Style</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.style.toUpperCase()}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Pose</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.pose}
                        </Typography>
                      </Grid>
                      {item.width && item.height &&
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Size</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.width}x{item.height}
                        </Typography>
                      </Grid>
                      }
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Seed</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.seed}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Created By</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {item.ownerName || "Anonymous"}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography variant="h6">
                          <strong>Created At</strong>
                        </Typography>
                        <Typography variant="subtitle" paragraph={true}>
                          {new Date(item.createdAt.seconds * 1000).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12} style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Button variant="outlined" edge="start" className={classes.iconButton} endIcon={<CopyIcon />} onClick={() => handleClone(item)}>
                            Clone
                        </Button>
                        {auth.user && auth.user.uid == item.owner &&
                        <Button variant="outlined" edge="start" className={classes.iconButton} endIcon={<DeleteIcon />} onClick={() => handleDelete(item.id)}>
                            Delete
                        </Button>
                        }
                        <Button variant="outlined"  className={classes.iconButton} endIcon={<FavoriteIcon color={likes[item.id]? 'primary':'white'} />} onClick={() => handleLike(item.id)}>
                          {(Boolean(item.likesCount) + (Boolean(likes[item.id]) - Boolean(initLikes[item.id]))) }
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        }
      </Container>
    </Section>
  );
}

export default ItemSection;
